html {
  position: relative;
  min-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

/* body > .container {
  padding: 60px 15px 0;
} */

/* .footer > .container {
  padding-right: 15px;
  padding-left: 15px;
} */


.container-fluid {
  height: 100vh;
  width: 100vw;
  padding: 0px;
}


  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
